.block-modal-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.3);
  z-index: 1010;
}

.block-modal-content {
  background: white;
  width: 520px;
  height: 100vh;
  position: fixed;
  right: -520px;
  top: 0;
  animation: slideInFromRight 0.5s ease-out forwards;
  overflow-y: auto;
  .error {
    color: red;
    font-family: "Inter";
    font-size: 0.7rem;
    margin-top: 0.5rem;
  }
  .alert-warning {
    width: 90%;
    margin: auto;
    border: 1px solid #f7e1a1;
    background-color: #fdf8e8;
    padding: 0.75rem;
    border-radius: 0.25rem;
    display: flex;
    align-items: start;
    margin-top: 1rem;

    .left-content {
      font-family: "Inter";
      font-size: 0.875rem;
      font-weight: 400;
      color: #5c5f62;
      width: 10%;
      display: flex;
      align-items: center;
      column-gap: 0.5rem;
      .cancel {
        height: 1rem;
        width: 1rem;
        background-color: #deaf21;
        border-radius: 50%;
        display: flex;
        align-items: start;
        column-gap: 0.5rem;
        justify-content: center;
        .danger {
          height: 0.5rem;
          width: 0.5rem;
        }
        .warning {
          height: 0.85rem;
          width: 0.85rem;
        }
      }
    }
    .right-content {
      width: 90%;
      display: flex;
      font-family: "Inter";
      font-size: 0.875rem;
      font-weight: 400;
      color: #1a1a1a;
    }
    .close {
      cursor: pointer;
      height: 0.75rem;
      width: 0.75rem;
      filter: brightness(0) saturate(100%) invert(59%) sepia(4%) saturate(5%)
        hue-rotate(356deg) brightness(85%) contrast(82%);
      margin-left: 0.2rem;
    }
  }
  .alert-danger {
    width: 95%;
    margin: auto;
    border: 1px solid #ebadad;
    background-color: #faebeb;
    padding: 0.75rem;
    border-radius: 0.25rem;
    display: flex;
    align-items: center;
    margin-top: 1rem;

    .left-content {
      width: 10%;
      display: flex;
      align-items: center;
      column-gap: 0.5rem;
      .cancel {
        height: 1rem !important;
        width: 1rem !important;
        background-color: #cc3333;
        border-radius: 50%;
        display: flex;
        align-items: center;
        column-gap: 0.5rem;
        justify-content: center;
        img {
          height: 0.5rem;
          width: 0.5rem;
        }
      }
    }
    .right-content {
      width: 90%;
      display: flex;
      font-family: "Inter";
      font-size: 0.875rem;
      font-weight: 400;
      color: #1a1a1a;
    }
  }
  .container {
    .header-container {
      border-bottom: 2px solid #e8e8e8;

      padding: 1.2rem 2.625rem;
      .header {
        font-family: Inter;
        font-size: 1.125rem;
        font-weight: 500;
        display: flex;
        align-items: center;
        justify-content: space-between;
        img {
          height: 1.5rem;
          width: 1.5rem;
          cursor: pointer;
        }
      }
      .date-cont {
        font-size: 1rem;
        font-family: Inter;
        color: #84818a;
        margin-top: 0.2rem;
      }
    }

    .content {
      padding: 2.625rem;
      .btn-cont {
        margin-top: 2rem;
      }
      .head {
        font-family: "Inter";
        font-size: 0.875rem;
        display: flex;
        align-items: center;
        column-gap: 12px;
        margin-top: 30px;
        .check {
          height: 1rem;
          width: 1rem;
          border: 1px solid #cccccc;
          border-radius: 3px;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          .checked {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 1rem;
            width: 1rem;
            background: #6f47eb;

            img {
              height: 0.7rem;
              width: 0.7rem;
              filter: brightness(0) saturate(100%) invert(100%) sepia(8%)
                saturate(29%) hue-rotate(228deg) brightness(107%) contrast(99%);
              margin: 0;
            }
          }
          img {
            height: 1rem;
            width: 1rem;
          }
        }
      }
      .active {
        display: flex;
        justify-content: space-between;
        margin-top: 2rem;
        color: #444444;
        font-family: "Inter";
        font-size: 0.8rem;
        font-weight: 500;
        line-height: 1.2rem;
        margin-bottom: 2rem;
        align-items: center;
        .switch {
          position: relative;
          display: inline-block;
          width: 44px;
          height: 24px;
          margin-right: -2rem !important;
        }

        .switch input {
          opacity: 0;
          width: 0;
          height: 0;
        }

        .slider {
          position: absolute;
          cursor: pointer;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background-color: #ccc;
          transition: 0.4s;
          border-radius: 34px;
        }

        .slider:before {
          position: absolute;
          content: "";
          height: 20px;
          width: 20px;
          left: 2px;
          bottom: 2.05px;
          background-color: white;
          transition: 0.4s;
          border-radius: 50%;
        }

        input:checked + .slider {
          background-color: #6f47eb;
        }

        input:checked + .slider:before {
          transform: translateX(20px);
        }

        /* Rounded sliders */
        .slider.round {
          border-radius: 34px;
        }

        .slider.round:before {
          border-radius: 50%;
        }
      }
      .selections-container {
        display: flex;
        margin-top: 2rem;
        column-gap: 3rem;
        .select {
          display: flex;
          align-items: center;
          column-gap: 10px;
          color: #444444;
          font-family: "Inter";
          font-size: 0.8rem;
          font-weight: 500;
          .check {
            height: 1rem;
            width: 1rem;
            border: 1px solid #cccccc;
            border-radius: 3px;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            .checked {
              display: flex;
              justify-content: center;
              align-items: center;
              height: 1rem;
              width: 1rem;
              background: #6f47eb;

              img {
                height: 0.7rem;
                width: 0.7rem;
                filter: brightness(0) saturate(100%) invert(100%) sepia(8%)
                  saturate(29%) hue-rotate(228deg) brightness(107%)
                  contrast(99%);
                margin: 0;
              }
            }
            img {
              height: 1rem;
              width: 1rem;
            }
          }
        }
      }
      .time-container {
        display: flex;
        justify-content: space-between;
        margin-top: 2rem;
        color: #444444;
        font-family: "Inter";
        font-size: 0.8rem;
        font-weight: 500;
        line-height: 1.2rem;
        .start {
          select {
            margin-top: 0.5rem;
            width: 11.25rem;
            padding: 0.6rem 1rem;
            font-size: 1rem;
            border-radius: 0.25rem;
            border: 1px solid #e8e8e8;
            outline: none;
            font-family: "Inter";
            font-size: 0.875rem;
            font-weight: 400;
            color: #202020;
            -webkit-appearance: none; /* Remove default arrow in Chrome and Safari */
            -moz-appearance: none; /* Remove default arrow in Firefox */
            appearance: none; /* Remove default arrow in other browsers */
            background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="10" height="5"><path d="M0 0l5 5 5-5H0z" fill="gray"/></svg>');
            background-repeat: no-repeat;
            background-position: right 0.7rem top 50%, 0 0; /* Position the arrow icon */
            background-size: 10px auto, 100%;
          }
        }
      }
      .date-cont {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 2rem;
        color: #444444;
        font-family: "Inter";
        font-size: 0.8rem;
        font-weight: 500;
      }
      .header {
        font-family: Inter;
        font-size: 1rem;
        font-weight: 500;
        margin-bottom: -10px;
        margin-top: -20px;
      }
    }
    .blocked-data-conatiner {
      border-top: 3px solid #e8e8e8;
      overflow-y: auto;
      height: calc(100vh - 525px);
      .content {
        padding: 2.625rem;
        .no-booking {
          font-family: "Inter";
          font-size: 0.875rem;
          font-weight: 400;
          color: #84818a;
          padding: 1rem 1.5rem;
        }

        .empty {
          height: 100px;
          display: flex;
          justify-content: center;
          .loader {
            border: 0.1rem solid #9d9d9d;
            border-top: 0.15rem solid transparent;
            border-radius: 50%;
            width: 1rem;
            height: 1rem;
            animation: spin 1s linear infinite;
            margin: auto;
          }
          @keyframes spin {
            0% {
              transform: rotate(0deg);
            }
            100% {
              transform: rotate(360deg);
            }
          }
        }
        .header {
          font-family: Inter;
          font-size: 1rem;
          font-weight: 600;
          margin-bottom: 26px;
        }
        .blocked-content {
          padding: 22px 21px;
          border: 1px solid #ededed;
          border-radius: 0.5rem;
          display: flex;
          justify-content: space-between;
          margin-bottom: 1.5rem;
          .left-cont {
            font-family: Inter;
            font-size: 0.875rem;
            font-weight: 500;
            line-height: 16.94px;
            text-align: left;
            color: #202020;
            .select-opt {
              margin-top: 0.3rem;
              color: #727272;
            }
          }
          .right-cont {
            .delete {
              display: flex;
              justify-content: center;
              align-items: center;
              color: #fff;
              font-family: "Inter";
              font-size: 0.875rem;
              font-weight: 500;
              cursor: pointer;
              background-color: #cc3333;
              height: 2.25rem;
              width: 4.375rem;
              border-radius: 2px;
              .loading-ic {
                border: 0.1rem solid #fff;
                border-top: 0.15rem solid transparent;
                border-radius: 50%;
                width: 1rem;
                height: 1rem;
                animation: spin 1s linear infinite;
              }
              @keyframes spin {
                0% {
                  transform: rotate(0deg);
                }
                100% {
                  transform: rotate(360deg);
                }
              }
            }
          }
        }
      }
    }
  }
}

@keyframes slideInFromRight {
  0% {
    right: -520px;
  }
  100% {
    right: 0;
  }
}
