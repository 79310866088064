.SideBar {
  width: 4.95rem;
  height: 100vh;
  background: #fff;
  border-right: 1px solid #ededed;
  position: fixed;
  z-index: 1001;
  .homeIC {
    height: 1.75rem;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 2rem;
    padding-bottom: 2rem;
    margin-bottom: 1.25rem;
    cursor: pointer;
    position: relative;
    img {
      height: 39px;
    }
    .slide {
      position: absolute;
      height: 1.5rem;
      width: 1.5rem;
      background-color: #ffffff;
      border: 1.5px solid #0000001a;
      border-radius: 50%;
      left: 4rem;
      top: 2rem;
      display: flex;
      justify-content: center;
      align-items: center;
      .left {
        height: 0.75rem;
        width: 0.75rem;
      }
    }
  }

  hr {
    border-top: 1px solid #e4e4e4;
  }
  .addBtn {
    background-color: #6f47eb;
    height: 3rem;
    width: 3rem;
    margin: auto;
    border-radius: 0.75rem;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 1.2rem;
    cursor: pointer;

    img {
      height: 1.2rem;
      width: 1.2rem;
    }
  }
  .admin {
    font-family: "Inter";
    font-size: 0.75rem;
    font-weight: 500;
    line-height: 16px;
    text-align: center;
    color: #b2b3bd;
    margin-bottom: 1rem;
  }
  .iconList {
    height: 3rem;
    width: 3rem;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    margin-bottom: 0.5rem;
    img {
      height: 1rem;
      width: 1rem;
      cursor: pointer;
    }
  }
  .iconList:hover {
    background-color: #eee9fd;
    border-radius: 0.75rem;
  }
  .iconList,
  .iconListSelected {
    position: relative;
    cursor: pointer;
  }

  .subTypePopup {
    position: absolute;
    left: 4rem;
    top: 0;
    background: white;
    z-index: 1000;
    display: flex;
    flex-direction: column;
    width: 10.813rem;
    border: 1px solid #ededed;
    text-transform: capitalize;
    .type {
      padding: 14px 24px;
      font-family: Inter;
      font-size: 0.875rem;
      font-weight: 500;
      text-align: left;
      text-transform: capitalize;
      color: #808191;
      border-bottom: 1px solid #e4e4e4;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .close {
        font-family: "Inter";
        font-size: 2rem;
        font-weight: 600;
        color: #11142d;
        line-height: 14px;
      }
    }
    .subTypeItem {
      padding: 14px 24px;
      font-family: "Inter";
      font-size: 0.875rem;
      font-weight: 400;
      color: #808191;
      cursor: pointer;
      &:hover {
        color: #6f47eb;
      }
    }
  }

  .iconListSelected {
    height: 3rem;
    width: 3rem;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    background-color: #eee9fd;
    border-radius: 0.75rem;
    margin-bottom: 0.5rem;

    img {
      height: 1rem;
      width: 1rem;
      cursor: pointer;
    }
  }
  .expand {
    position: absolute;
    bottom: 3rem;
    right: 1.5rem;
    height: 1.6rem;
    width: 1.6rem;
    background-color: #6f47eb;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    z-index: 10;

    img {
      height: 0.75rem;
      width: 0.75rem;
      transform: rotate(90deg);
      filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(0%)
        hue-rotate(285deg) brightness(105%) contrast(102%);
    }
  }
}
